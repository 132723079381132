<template>
    <div></div>
</template>

<script>
import Highcharts from 'highcharts'
export default {
    props: {
        title: {
            default: null
        },
        categories: {
            type: Array,
            required: true,
        },
        series: {
            type: Array,
            required: true,
        },
    },
    mounted() {
        const seriesDefault = {
            data: [],
        }
        let options = {
            chart: {
                type: 'bar'
            },
            colors: ['#5111F0', '#ca2a5a', '#efd947', '#5FD480', '#d85f7f', '#F9ED98', '#ae96e6', '#fde836'],
            title: {
                text: this.title
            },
            xAxis: {
                categories: this.categories,
                min: 0,
                title: {
                    text: null
                },
            },
            yAxis: {
                min: 0,
                tickInterval: 10,
                tickAmount: 11,
                labels: {
                    format: '{value}%'
                },
                title: {
                    text: null
                },
            },
            tooltip: {
                formatter() {
                    return `${this.series.name} - ${this.x}: ${this.y}%`;
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                        format: '{y}%'
                    }
                },
                series: {
                    pointPadding: 0,
                }
            },
            credits: {
                enabled: false
            },
            series: []
        }
        this.series.forEach(value => {
            options.series.push({
                ...seriesDefault,
                ...value,
            })
        })
        Highcharts.chart(this.$el, options);
    }
}
</script>