<script>
import humanizeDuration from 'humanize-duration'

export default {
    props: {
        timeTillEventStart: Number,
        timeTillEventEnd: Number,
    },
    data() {
        return {
            window
        }
    },
    mounted() {
        this.$el.removeAttribute('style')
    },
    computed: {
        humanDate() {
            return humanizeDuration(this.timeTillEventStart, {
                delimiter: " and ",
                units: ["y", "mo", "w", "d", "h", "m"],
                round: true
            })
        },
        ended() {
            return this.timeTillEventEnd <= 0
        },
        started() {
            return this.timeTillEventStart <= 0
        },
        startingSoon() {
            const mins = 30
            return this.timeTillEventStart < mins * 60 * 1000
        }
    },
}
</script>
