<template>
    <div class="container">
        <div class="w-full md:w-11/12 lg:max-w-xl mx-auto pt-5 pb-1 px-10 bg-white rounded-large shadow-xl font-point-demo-bold text-blue">
            <div v-for="type in ['guides', 'djs']" :key="type" class="mb-5">
                <div class="text-2xl text-magenta mb-4 text-center uppercase">{{ type }}</div>
                <div v-for="person in getType(type)" :key="`${person.name}_${person.stream_id}`"
                     class="flex items-center mt-4">
                    <div class="w-1/3">{{ person.name }}<br>#{{ person.stream_id }}</div>
                    <div class="w-full flex items-center">
                        <div class="mr-4 w-6">
                            <svg v-if="streams[person.stream_id].loading" height="20" class="inline-block" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#fd3670"><g fill="none" fill-rule="evenodd" stroke-width="2"><circle cx="22" cy="22" r="1"><animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"/><animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"/></circle><circle cx="22" cy="22" r="1"><animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"/><animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"/></circle></g></svg>

                            <svg v-if="streams[person.stream_id].playing && !streams[person.stream_id].loading"
                                 @click="pause(person.stream_id)"
                                 height="20" class="inline-block cursor-pointer fill-current hover:text-magenta" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 74"><path d="M8,73.4c-4.1,0-7.5-3.4-7.5-7.5V8c0-4.1,3.4-7.5,7.5-7.5s7.5,3.4,7.5,7.5v57.9C15.5,70.1,12.1,73.4,8,73.4z"/><path d="M40,73.4c-4.1,0-7.5-3.4-7.5-7.5V8c0-4.1,3.4-7.5,7.5-7.5s7.5,3.4,7.5,7.5v57.9C47.5,70.1,44.1,73.4,40,73.4z"/></svg>

                            <svg v-if="!streams[person.stream_id].playing && !streams[person.stream_id].loading"
                                 @click="play(person.stream_id)"
                                 height="20" class="inline-block cursor-pointer fill-current hover:text-magenta" viewBox="0 0 55 62" xmlns="http://www.w3.org/2000/svg"><path d="M52.5 26.6699C55.8333 28.5944 55.8333 33.4056 52.5 35.3301L7.5 61.3109C4.16667 63.2354 -3.05236e-06 60.8298 -2.88411e-06 56.9808L-6.128e-07 5.01924C-4.44555e-07 1.17024 4.16667 -1.23539 7.5 0.689108L52.5 26.6699Z"/></svg>
                        </div>
                        <input
                            type="range" min="0" max="1" step="0.1"
                            v-model="streams[person.stream_id].volume"
                            class="w-full"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Howl } from 'howler'

export default {
        props: {
            djs: Array,
            guides: Array,
        },
        data() {
            return {
                streams: {},
                players: {},
            }
        },
        created() {
            Object.keys(this.djs).forEach(s => {
                this.$set(this.streams, this.djs[s].stream_id, {
                    loading: false,
                    playing: false,
                    volume: this.djs[s].volume,
                    url: this.djs[s].stream_url,
                })
            })
            Object.keys(this.guides).forEach(s => {
                this.$set(this.streams, this.guides[s].stream_id, {
                    loading: false,
                    playing: false,
                    volume: this.guides[s].volume,
                    url: this.guides[s].stream_url,
                })
            })
        },
        mounted() {
            Object.keys(this.streams).forEach(streamId => {
                this.players[streamId] = new Howl({
                    src: this.streams[streamId].url,
                    format: ['mp3', 'aac'],
                    html5: true,
                    volume: this.streams[streamId].volume,
                    onplay: () => {
                        this.$set(this.streams[streamId], 'playing', true)
                        this.$set(this.streams[streamId], 'loading', false)
                    },
                    onpause: () => {
                        this.$set(this.streams[streamId], 'playing', false)
                    }
                })

                this.$watch(`streams.${streamId}.volume`, volume => {
                    this.players[streamId].volume(volume)
                })
            })
        },
        methods: {
            getType(type) {
                return this[type]
            },
            play(streamId) {
                if (this.players[streamId].playing()) {
                    return
                }
                this.$set(this.streams[streamId], 'loading', true)
                this.players[streamId].play()
            },
            pause(streamId) {
                if (!this.players[streamId].playing()) {
                    return
                }
                this.players[streamId].pause()
            }
        }
    }
</script>
