<script>
    export default {
       props: {
            expandable: {
                default: true,
                type: Boolean
            }
        },
        data() {
            return {
                isActive: false
            }
        },
        methods: {
            toggle() {
                if (!this.expandable) {
                    return
                }
                this.isActive = !this.isActive
            }
        }
    }
</script>
