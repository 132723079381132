<script>
    import { Howl } from 'howler'

    export default {
        props: {
            djs: Array,
            guide: Object,
        },
        data() {
            return {
                loaded: false,
                playing: false,
                helpMessage: true,
                currentDj: this.djs[0],
                audioPlayerDj: null,
                audioPlayerDjId: null,
                audioPlayerGuide: null,
                audioPlayerGuideId: null,
            }
        },
        mounted() {
            this.$el.removeAttribute('style')
            this.buildGuideAudioPlayer()
            this.buildDjAudioPlayer()
        },
        methods: {
            buildDjAudioPlayer() {
                this.loaded = false
                this.playing = false

                this.audioPlayerDj = new Howl({
                    src: this.currentDj.stream_url,
                    format: ['mp3', 'aac'],
                    html5: true,
                    volume: this.currentDj.volume / 100,
                    onload: () => {
                        this.loaded = true
                    },
                    onplay: () => {
                        this.loaded = true
                        this.playing = true
                    },
                    onpause: () => {
                        this.playing = false
                    }
                })
            },
            buildGuideAudioPlayer() {
                this.loaded = false
                this.playing = false

                this.audioPlayerGuide = new Howl({
                    src: this.guide.audio_stream_url,
                    format: ['mp3', 'aac'],
                    html5: true,
                    volume: 1,
                    onload: () => {
                        this.loaded = true
                    },
                    onplay: () => {
                        this.loaded = true
                        this.playing = true
                    },
                    onpause: () => {
                        this.playing = false
                    }
                })
            },
            play() {
                this.loaded = false
                this.helpMessage = false;

                if (!this.audioPlayerDjId || !this.audioPlayerDj.playing(this.audioPlayerDjId)) {
                    this.audioPlayerDjId = this.audioPlayerDj.play()
                }
                if (!this.audioPlayerGuideId || !this.audioPlayerGuide.playing(this.audioPlayerGuideId)) {
                    this.audioPlayerGuideId = this.audioPlayerGuide.play()
                }
            },
            pause() {
                this.audioPlayerDj.pause()
                this.audioPlayerGuide.pause()
            },
            setCurrentDj(id) {
                this.currentDj = this.djs.find(dj => dj.id === id)

                if (this.audioPlayerDj) {
                    this.audioPlayerDj.unload()
                    this.buildDjAudioPlayer()
                    this.play()
                }
            }
        }
    }
</script>
