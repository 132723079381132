<template>
    <button @click="showModal(payload)" :class="classes"><slot></slot></button>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    props: {
        text: String,
        component: String,
        data: Object,
        classes: String
    },

    data() {
        return {
            payload: {}
        }
    },

    created() {
        this.payload.component = this.component
        this.payload.data = this.data
    },

    methods: {
        ...mapMutations([`showModal`]),
    }
}
</script>
