import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const mutations = {
    showModal(state, payload) {
        state.modalVisible = true;
        state.modalComponent = payload.component;
        state.modalData = payload.data;
    },
    hideModal(state) {
        state.modalVisible = false;
        state.modalComponent = null;
        state.modalData = null;
    },
    setAttendee (state, attendee) {
        state.attendee = attendee
    }
};

export const state = {
    modalVisible: false,
    modalComponent: null,
    modalData: null,
    attendee: false
};

export const store = new Vuex.Store({
    mutations,
    state,
});
