import Vue from 'vue'
import AOS from 'aos'
import { store } from './store';
import { mapMutations } from 'vuex';
Vue.config.productionTip = false;

/**
 * Register all Vue components
 */
const files = require.context('./components', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

const el = document.getElementById('app');
if (el) {
    const app = new Vue({
        el,
        store,
        created() {
            AOS.init()
        },
        mounted() {
            const attendee = document.body.dataset.attendee;
            this.setAttendee(attendee);
        },
        methods: {
            ...mapMutations([`setAttendee`]),
            redirect(url) {
                window.location = url
            }
        }
    });
}
