<template>
    <div
        class="relative p-16"
        style="background: url('/assets/front/img2/almost-circle.svg') no-repeat center center; background-size: cover"
    >
        <div class="relative w-full h-full flex items-center justify-center">
            <div
                ref="circle"
                class="absolute w-full h-full top-0 left-0 transform -rotate-90"
            ></div>
            <!-- <div class="relative">
                <div class="text-white uppercase text-center mb-6 md:mb-16">
                    <div class="text-4xl sm:text-6xl md:text-14xl font-point-demo-bold leading-none">{{ remaining }}</div>
                    <div class="text-md sm:text-xl md:text-2xl sm:-mt-4">spots remaining</div>
                </div>
                <div class="text-blue uppercase text-center">
                    <div class="text-4xl sm:text-6xl md:text-14xl font-point-demo-bold leading-none">{{ taken }}</div>
                    <div class="text-md sm:text-xl md:text-2xl sm:-mt-4">spots taken</div>
                </div>
v> -->
            <div class="relative">
                <div class="text-white text-center text-lg sm:text-4xl sm:px-20 mb-10">
                    The music is calling you...let your body answer
                </div>
                <div class="text-white text-center text-sm sm:text-2xl">
                    - Lori Saltzman
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        remaining: Number,
        taken: Number
    },
    mounted() {
        if (window.innerWidth < 768) {
            return;
        }

        const colorTaken = "#034E82";
        const colorRemaining = "#FFFFFF";
        let taken = this.taken;

        let dotSize = 10;
        let total = this.total;

        if (this.total > 130) {
            dotSize = 6;
        }

        if (this.total > 200) {
            dotSize = 4;
            total = 200;

            taken = Math.round((this.taken * total) / this.total);
        }

        const div = 360 / total;
        const parent = this.$refs.circle;
        const offsetToParentCenter = parseInt(parent.offsetWidth / 2);
        const offsetToChildCenter = dotSize / 2;
        const radius = offsetToParentCenter - 20;
        const totalOffset = offsetToParentCenter - offsetToChildCenter;

        for (let i = 0; i < total; ++i) {
            const dot = document.createElement("div");
            dot.style.position = "absolute";
            dot.style.width = `${dotSize}px`;
            dot.style.height = `${dotSize}px`;
            dot.style.borderRadius = "50%";
            dot.style.backgroundColor =
                i >= taken ? colorRemaining : colorTaken;

            const y = Math.sin(div * i * (Math.PI / 180)) * radius;
            const x = Math.cos(div * i * (Math.PI / 180)) * radius;
            dot.style.top = (y + totalOffset).toString() + "px";
            dot.style.left = (x + totalOffset).toString() + "px";
            parent.appendChild(dot);
        }
    },
    computed: {
        total() {
            return this.remaining + this.taken;
        }
    }
};
</script>
