<script>
    export default {
        data() {
            return {
                questions: [
                    // {
                    //     id: 1,
                    //     img: 'dancing-with-masks.svg',
                    //     text: 'I will practice safe social-distancing from other participants'
                    // },
                    // {
                    //     id: 2,
                    //     img: 'dancing-with-masks.svg',
                    //     text: 'I will leave my mask on'
                    // },
                    // {
                    //     id: 3,
                    //     img: 'dancing-with-masks.svg',
                    //     text: 'I promise to be mindful of other peoples spaces'
                    // },
                    {
                        id: 4,
                        img: 'dancing-with-masks.svg',
                        text: 'I agree to the terms and conditions. Attending this event is my  own responsibility'
                    }
                ],
                currentQuestion: null
            }
        },
        created() {
            this.getCurrentQuestion()
        },
        mounted() {
            this.$el.removeAttribute('style')
        },
        methods: {
            confirm(questionId) {
                sessionStorage.setItem(`question_${questionId}`, '1')
                this.getCurrentQuestion()
            },
            getCurrentQuestion() {
                if (this.allAccepted()) {
                    this.onAllAccepted()
                } else {
                    this.questions.every(question => {
                        if (!sessionStorage.getItem(`question_${question.id}`)) {
                            this.currentQuestion = question;
                            return false
                        } else {
                            return true
                        }
                    });
                }
            },
            allAccepted() {
                return this.questions.every(question => {
                    return sessionStorage.getItem(`question_${question.id}`);
                });
            },
            onAllAccepted() {
                this.currentQuestion = null
                this.$emit('on-all-accepted', true)
            }
        }
    }
</script>
