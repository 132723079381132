<script>
    export default {
        data: () => ({
            mediaType: null,
        }),
        mounted() {
            this.$el.removeAttribute('style')
        },
        watch: {
            mediaType() {
                this.$emit('on-selected', this.mediaType)
            }
        }
    }
</script>
