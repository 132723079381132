<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            site: null,
            isMenuOpen: false
        }
    },
    computed: {
        ...mapState(['attendee']),
    },
    mounted() {
        this.site = document.querySelector('.site')
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen
            this.site.classList.toggle('menu-active')
        },

        closeMenu() {
            this.isMenuOpen = false;
            this.site.classList.remove('menu-active')
        },
    }
}
</script>
