<script>
    import axios from 'axios'

    export default {
        props: {
            attendee: Object,
            attendeeEventLocation: Object,
            checkInUrl: {
                type: String,
                required: true,
            },
            zoomUrl: {
                type: String,
            }
        },
        data() {
            return {
                termsAccepted: false,
                mediaType: false,
                canSeeStream: false,
                onLocation: false,
                message: '',
                errors: []
            }
        },
        mounted() {
            this.$el.removeAttribute('style');
        },
        methods: {
            checkIn() {
                this.message = 'Checking for a free spot...';

                axios.post(this.checkInUrl, {
                    attendee_id: this.attendee.id,
                    location_id: this.attendeeEventLocation.id,
                })
                .then(() => {
                    if (this.mediaType === 'zoom' && this.zoomUrl) {
                        return window.location.href = this.zoomUrl
                    }
                    setTimeout(() => {
                        this.message = 'Loading event page...';

                        setTimeout(() => this.canSeeStream = true, 2000);
                    }, 2000);
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = Object.values(error.response.data.errors).flat();
                        this.message = '';
                    }
                    if (error.response.status === 419) {
                        this.message = 'Your session has expired. Please refresh the page!';
                    } else {
                        this.message = 'An unknown error occurred';
                    }
                })
            },
            located() {
                this.onLocation = true;
            },
            allTermsAccepted() {
                this.termsAccepted = true;

                if (!this.zoomUrl) {
                    this.mediaType = 'audio';
                    this.checkIn()
                }
            },
            mediaTypeSelected(type) {
                this.mediaType = type;

                this.checkIn()
            }
        }
    }
</script>
