<script>
    import axios from 'axios'

    Number.prototype.toRad = function () {
        return this * Math.PI / 180
    }
    Number.prototype.toUnits = function () {
        return this < 1 ? this.toFixed(3) + ' mi' : `${this} mi`
    }
    export default {
        props: {
            location: Object,
            attendeeId: Number,
            si: String,
            isSi: Boolean,
        },
        data() {
            return {
                message: 'Please make sure you ALLOW access to your location, otherwise it will not work!',
                error: null,
                freePass: false,
                distanceAway: null
            }
        },
        mounted() {
            this.$el.removeAttribute('style')
            this.checkPosition()

            if (this.isSi) {
                this.userIsOnLocation()
            }
        },
        methods: {
            distance(lat1, lng1, lat2, lng2) {
                const R = 6371 // km
                const dLat = (lat2 - lat1).toRad()
                const dLon = (lng2 - lng1).toRad()
                const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.cos(lat1.toRad()) * Math.cos(lat2.toRad()) *
                    Math.sin(dLon / 2) * Math.sin(dLon / 2)
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
                return (R * c)
            },
            checkPosition() {
                if (this.location.global) {
                    return this.userIsOnLocation()
                }

                if (!navigator.geolocation) {
                    return this.error = "Sorry, your browser does not support HTML5 geolocation."
                }

                navigator.geolocation.getCurrentPosition(position => {
                    let myLat = position.coords.latitude
                    let myLng = position.coords.longitude

                    const distanceMiles = this.distance(myLat, myLng, this.location.area_lat, this.location.area_lng) / 1.60934
                    const radius = this.location.checkin_radius

                    if (distanceMiles - radius <= 0) {
                        return this.userIsOnLocation()
                    }

                    this.distanceAway = parseFloat(distanceMiles.toFixed(2)).toUnits()
                    this.message = null
                }, error => {
                    this.message = null

                    if (error.code === GeolocationPositionError.PERMISSION_DENIED) {
                        this.freePass = true
                        return this.error = 'Without this permission we cannot check if you are on the location and cannot let you participate, sorry.'
                    }
                    if (error.code === GeolocationPositionError.POSITION_UNAVAILABLE) {
                        this.freePass = true
                        return this.error = 'Your browser did not provide us your position. We cannot check if you are on the location and cannot let you participate, sorry.'
                    }
                    if (error.code === GeolocationPositionError.TIMEOUT) {
                        this.freePass = true
                        return this.error = 'Your browser timed out to provide us your position. We cannot check if you are on the location and cannot let you participate, sorry.'
                    }

                    this.error = error.message
                })
            },
            userIsOnLocation() {
                this.$emit('on-location', true)
            },
            sneakIn() {
                axios.post(this.si, {
                    location_id: this.location.id,
                    attendee_id: this.attendeeId,
                }).then(_ => {
                    this.$emit('on-location', true)
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.error = Object.values(error.response.data.errors).flat()[0];
                    }
                    else if (error.response.status === 419) {
                        this.error = 'Your session has expired. Please refresh the page!';
                    } else {
                        this.error = 'An unknown error occurred';
                    }
                })
            }
        }
    }
</script>
