<script>
    export default {
        data() {
            return {
                slide: 1,
                guide: 1
            }
        },
        mounted() {
            this.$el.removeAttribute('style')
        },
    }
</script>
