<template>
    <div class="font-point-demo-bold text-lg">
        <span @click="confirm" class="text-magenta cursor-pointer"><slot></slot></span>
        <div v-if="confirming" class="pt-2">
            <span class="text-blue">
                <slot name="question">Are you sure?</slot>
            </span>
            <form method="post" :action="url">
                <input type="hidden" name="_token" v-model="csrf">
                <button class="border-2 rounded-full px-5 pt-3 pb-2 cursor-pointer uppercase leading-none border-magenta text-magenta hover:bg-magenta hover:text-white">yes</button>
                <button @click="cancel" type="button" class="border-2 rounded-full px-5 pt-3 pb-2 cursor-pointer uppercase leading-none border-blue text-blue hover:bg-blue hover:text-white">no</button>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            url: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                confirming: false,
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        },
        methods: {
            confirm() {
                this.confirming = true
            },
            cancel() {
                this.confirming = false
            },
        }
    }
</script>
