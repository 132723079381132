<template>
    <div>
        <form ref="form" @submit.prevent="signUp()" class="py-4">
            <input type="hidden" name="type" :value="data.type">

            <div v-if="loading" class="w-full flex justify-center pt-8 pb-4">
                <img src="/assets/front/img/loader2.svg" width="84px" height="84px"/>
            </div>

            <div v-else>
                <div v-if="sent">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280.95 276.22" width="40" class="mx-auto mb-10">
                        <g>
                            <path fill="#fd3670" class="fill"
                                  d="M142.86,276.22h-4.77C61.95,276.22,0,214.27,0,138.13a33.17,33.17,0,0,1,66.34,0,71.83,71.83,0,0,0,71.75,71.75h4.77a71.83,71.83,0,0,0,71.75-71.75,33.17,33.17,0,0,1,66.34,0C280.95,214.27,219,276.22,142.86,276.22Z"/>
                            <circle fill="#736ce4" class="fill" cx="33.17" cy="33.17" r="33.17"/>
                            <circle fill="#736ce4" class="fill" cx="247.78" cy="33.17" r="33.17"/>
                        </g>
                    </svg>

                    <h2 class="font-point-demo-bold text-blue text-lg text-center uppercase">
                        We've sent you a text, we're excited to have you  at Open Floor
                         <!-- <span class="magenta">DISCOLOGY</span> with us. -->
                    </h2>
                </div>

                <div v-if="!sent">
                    <input
                        name="name"
                        v-model="name"
                        type="text"
                        placeholder="Your name"
                        required
                        class="appearance-none border-2 border-white focus:border-lightBlue rounded-large w-full py-3 px-5 mb-8 font-source-sans-pro text-lg bg-purpleLight text-purple focus:bg-white focus:text-blue2 focus:outline-none">

                    <input
                        name="email"
                        type="email"
                        v-model="email"
                        placeholder="Email address"
                        required
                        class="appearance-none border-2 border-white focus:border-lightBlue rounded-large w-full py-3 px-5 mb-8 font-source-sans-pro text-lg bg-purpleLight text-purple focus:bg-white focus:text-blue2 focus:outline-none">

                    <input
                        name="phone"
                        type="tel"
                        v-model="phone"
                        placeholder="Phone number"
                        required
                        class="appearance-none border-2 border-white focus:border-lightBlue rounded-large w-full py-3 px-5 font-source-sans-pro text-lg bg-purpleLight text-purple focus:bg-white focus:text-blue2 focus:outline-none">

                    <div v-if="errors.length > 0" class="py-4">
                        <div v-for="error in errors" class="text-magenta text-lg text-center mt-1">{{ error }}</div>
                    </div>

                    <div class="flex justify-center mt-8">
                        <button
                            type="submit"
                            class="w-full xsm:w-auto px-24 pt-4 pb-3 bg-lightBlue hover:bg-blue3 rounded-full leading-none font-point-demo-bold text-white text-xl sm:text-2xl">
                            GO
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: {
        data: Object,
    },

    data() {
        return {
            loading: false,
            sent: false,
            errors: [],
            name: null,
            email: null,
            phone: null,
            route: null
        }
    },

    created() {
        this.route = `/signup-${this.data.type}`
    },

    methods: {
        signUp() {
            const formData = new FormData(this.$refs.form)
            this.loading = true
            this.errors = []

            axios.post(this.route, formData)
                .then(() => {
                    setTimeout(() => {
                        this.loading = false
                        this.sent = true
                    }, 1000);
                })
                .catch(({response}) => {
                    this.loading = false
                    const errors = response.data.errors
                    const errorKeys = Object.keys(errors)

                    errorKeys.forEach(field => {
                        errors[field].forEach(error => this.errors.push(error))
                    })
                });
        }
    }
}
</script>
