<script>
import { TweenMax, TimelineMax } from 'gsap';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import {mapState} from "vuex";

export default {
    mounted() {
        this.scenes()
    },

    computed: {
        ...mapState(['attendee']),
    },

    methods: {
        scenes() {
            const preloader = document.getElementById('preloader');
            const body = document.body;
            const controller = new ScrollMagic.Controller();
            const videoEndTimeline = new TimelineMax({paused: true});
            const windowWidth = window.innerWidth;
            let scenes;

            function init() {
                window.addEventListener('resize', () => {
                    if (window.innerWidth !== windowWidth) {
                        location.reload();
                    }
                });

                if (windowWidth >= 768) {
                    enableVideo();
                    timelines();
                } else {
                    window.addEventListener('load', () => {
                        preloader.classList.add('hidden');
                        endVideoTimeline();
                        timelines();
                    });
                }
            }

            function enableVideo() {
                const video = document.getElementsByTagName('video')[0];
                const videoSource = video.getElementsByTagName('source')[0];
                videoSource.setAttribute('src', video.getAttribute('data-source'));

                if (video.readyState < 1) {
                    video.load();
                }

                video.addEventListener('loadeddata', function() {
                    if (video.readyState >= 2) {
                        setTimeout(() => {
                            preloader.classList.add('hidden');
                            body.classList.remove('overflow-hidden');
                            video.play();
                        }, 1000);
                    }
                });

                // endVideoTimeline();

                video.addEventListener('ended', function() {
                    endVideoTimeline();
                });
            }

            function endVideoTimeline() {
                videoEndTimeline.play();

                videoEndTimeline
                    .to('[data-bg-hero]', .6, {
                        autoAlpha: 1
                    }, 0)
                    .to('[data-interactive-header]', .6, {
                        autoAlpha: 1
                    }, 0)
                    .to('[data-video]', .6, {
                        autoAlpha: 0
                    }, 0)
                    .fromTo('[data-text-social]', 1,
                        {
                            x: -100,
                            autoAlpha: 0
                        },
                        {
                            x: 0,
                            autoAlpha: 1
                        }, .6)
                    .fromTo('[data-text-silence]', 1,
                        {
                            x: 100,
                            autoAlpha: 0
                        },
                        {
                            x: 0,
                            autoAlpha: 1
                        }, .6);
            }

            function timelines() {
                let sectionAboutTimeline = new TimelineMax();
                let sectionDiscologyTimeline = new TimelineMax();
                scenes = [];

                scenes[0] = new ScrollMagic.Scene({
                    triggerElement: '.section--about',
                    offset: 0,
                    triggerHook: 1,
                    duration: 2300,
                    reverse: true
                })
                    .setTween(sectionAboutTimeline)
                    .addTo(controller);

                scenes[1] = new ScrollMagic.Scene({
                    triggerElement: '.section--discology',
                    offset: 0,
                    duration: 4500,
                    reverse: true
                })
                    .setTween(sectionDiscologyTimeline)
                    .addTo(controller);

                scenes[2] = new ScrollMagic.Scene({
                    triggerElement: '.section--promises',
                    offset: 0,
                    triggerHook: 1,
                    duration: 0,
                    reverse: true
                })
                    .setTween(
                        TweenMax.to('.to-top-button', .2, {
                            autoAlpha: 1,
                        })
                    )
                    .addTo(controller);

                scenes[3] = new ScrollMagic.Scene({
                    triggerElement: '.section--contact',
                    triggerHook: 1,
                    offset: 0,
                    duration: 0,
                    reverse: true
                })
                    .setTween(
                        TweenMax.to('.to-top-button', .2, {
                            backgroundColor: '#d85f7f',
                        })
                    )
                    .addTo(controller);

                sectionAboutTimeline
                    .to('[data-bg-about]', .5, {
                        autoAlpha: 1
                    }, 0)
                    .fromTo('[data-quote-holder]', 2,
                        {
                            autoAlpha: 0,
                            scale: .6
                        },
                        {
                            autoAlpha: 1,
                            scale: 1
                        }, 0)
                    .to('[data-quote-holder]', 2, {
                        top: '20%',
                        x: '-50%',
                    }, 4)
                    .to('[data-sad-faces]', 2, {
                        autoAlpha: 1
                    }, 4)
                    .fromTo('.info-box-1', 1,
                        {
                            autoAlpha: 0,
                            scale: .6,
                        },
                        {
                            autoAlpha: 1,
                            scale: 1,
                        }, 6)
                    .to('#light_1, #light_2', .1, {
                        autoAlpha: 1,
                    }, 6.9)
                    .to('#eyes_1, #eyes_2', .1, {
                        fill: '#ffffff',
                    }, 6.9)
                    .fromTo('.info-box-2', 1,
                        {
                            autoAlpha: 0,
                            scale: .6,
                        },
                        {
                            autoAlpha: 1,
                            scale: 1,
                        }, 9)
                    .to('#light_3, #light_4', .1, {
                        autoAlpha: 1,
                    }, 9.9)
                    .to('#eyes_3, #eyes_4', .1, {
                        fill: '#ffffff',
                    }, 9.9)
                    .fromTo('.info-box-3', 1,
                        {
                            autoAlpha: 0,
                            scale: .6,
                        },
                        {
                            autoAlpha: 1,
                            scale: 1,
                        }, 12)
                    .to('#light_7, #light_8', .1, {
                        autoAlpha: 1,
                    }, 12.9)
                    .to('#eyes_7, #eyes_8', .1, {
                        fill: '#ffffff',
                    }, 12.9)
                    .fromTo('.info-box-4', 1,
                        {
                            autoAlpha: 0,
                            scale: .6,
                        },
                        {
                            autoAlpha: 1,
                            scale: 1,
                        }, 15)
                    .to('#light_5, #light_6', .1, {
                        autoAlpha: 1,
                    }, 15.9)
                    .to('#eyes_5, #eyes_6', .1, {
                        fill: '#ffffff',
                    }, 15.9)
                    .to('.eyes', 0, {
                        fill: '#ffffff'
                    }, 19);

                sectionDiscologyTimeline
                    .to('[data-bg-disco]', 1.5, {
                        autoAlpha: 1,
                    }, 0)
                    .to('#logo-letter-1', .5, {
                        autoAlpha: 1,
                    }, 1)
                    .to('#logo-letter-2', .5, {
                        autoAlpha: 1,
                    }, 2.5)
                    .to('#logo-letter-3', .5, {
                        autoAlpha: 1,
                    }, 3)
                    .to('#logo-letter-4', .5, {
                        autoAlpha: 1,
                    }, 3.5)
                    .to('#logo-letter-5', .5, {
                        autoAlpha: 1,
                    }, 4)

                    .to('#dg-scene-1', 1, {
                        autoAlpha: 1,
                    }, 5)
                    .to('#dg-scene-1', 1, {
                        autoAlpha: 0,
                    }, 6)

                    .to('#sd-logo-inner-text-1', .5, {
                        autoAlpha: 1,
                    }, 5)
                    .to('#sd-logo-inner-text-1', .5, {
                        color: '#fde836',
                    }, 5)
                    .to('#sd-logo-inner-text-1', .5, {
                        color: '#9d98ee',
                    }, 6)

                    .to('#dg-scene-2', 1, {
                        autoAlpha: 1,
                    }, 5.5)
                    .to('#dg-scene-2', 1, {
                        autoAlpha: 0,
                    }, 6.5)

                    .to('#sd-logo-inner-text-2', .5, {
                        autoAlpha: 1,
                    }, 6)
                    .to('#sd-logo-inner-text-2', .5, {
                        color: '#fde836',
                    }, 6)
                    .to('#sd-logo-inner-text-2', .5, {
                        color: '#9d98ee',
                    }, 7)

                    .to('#dg-scene-3', 1, {
                        autoAlpha: 1,
                    }, 6)
                    .to('#dg-scene-3', 1, {
                        autoAlpha: 0,
                    }, 7)

                    .to('#sd-logo-inner-text-3', .5, {
                        autoAlpha: 1,
                    }, 7)
                    .to('#sd-logo-inner-text-3', .5, {
                        color: '#fde836',
                    }, 7)
                    .to('#sd-logo-inner-text-3', .5, {
                        color: '#9d98ee',
                    }, 8)

                    .to('#dg-scene-4', 1, {
                        autoAlpha: 1,
                    }, 6.5)
                    .to('#dg-scene-4', 1, {
                        autoAlpha: 0,
                    }, 7.5)

                    .to('#sd-logo-inner-text-4', .5, {
                        autoAlpha: 1,
                    }, 8)
                    .to('#sd-logo-inner-text-4', .5, {
                        color: '#fde836',
                    }, 8)

                    .to('#sd-logo-inner-text-silence', .5, {
                        autoAlpha: 1,
                    }, 8)

                    .to('#dg-scene-5', 1, {
                        autoAlpha: 1,
                    }, 8)
                    .to('#dg-scene-5', 1, {
                        autoAlpha: 0,
                    }, 9)
                    .to('#dg-scene-6', 1, {
                        autoAlpha: 1,
                    }, 8.5)
                    .to('#dancingGirl', 3, {
                        x: '-70%',
                    }, 9.5)
                    .fromTo('.sd-logo-holder-2', 3,
                        {
                            x: window.innerWidth
                        },
                        {
                            x: window.innerWidth < 1441 ?
                                (window.innerWidth < 1025 ?
                                    (window.innerWidth < 769 ? 55 : 95)
                                    : 132)
                                : 215,
                        }, 9.5)
                    .fromTo('.sd-logo-holder-1', 2.5,
                        {
                            x: window.innerWidth < 769 ? 20 : 50
                        },
                        {
                            x: window.innerWidth < 1441 ? (window.innerWidth < 1025 ? -100 : -140) : -95,
                        }, 10)
                    .to('#logo-disco', 3, {
                        autoAlpha: .2,
                    }, 9.5)

                    .to('#sd-logo-text-1, #sd-logo-inner-text-silence', 2, {
                        autoAlpha: 0,
                    }, 10)

                    .to('#dg-scene-6', 1, {
                        autoAlpha: 0,
                    }, 12)

                    .to('[data-bg-ology]', 3, {
                        autoAlpha: 1,
                    }, 12.5)

                    .to('#gm-group-1', 1, {
                        autoAlpha: 1,
                    }, 12.5)
                    .to('#gm-group-1', 1, {
                        autoAlpha: 0,
                    }, 13.5)

                    .to('#gm-group-6', 1, {
                        autoAlpha: 1,
                    }, 12.5)
                    .to('#gm-group-6', 1, {
                        autoAlpha: 0,
                    }, 13.5)

                    .to('#sd-logo-inner-text-5', .5, {
                        autoAlpha: 1,
                    }, 12)
                    .to('#sd-logo-inner-text-5', .5, {
                        color: '#fde836',
                    }, 12)
                    .to('#sd-logo-inner-text-5', .5, {
                        color: '#e6afbe',
                    }, 13)

                    .to('#sd-logo-inner-text-6', .5, {
                        autoAlpha: 1,
                    }, 13)
                    .to('#sd-logo-inner-text-6', .5, {
                        color: '#fde836',
                    }, 13)
                    .to('#sd-logo-inner-text-6', .5, {
                        color: '#e6afbe',
                    }, 14)

                    .to('#gm-group-2', 1, {
                        autoAlpha: 1,
                    }, 13)
                    .to('#gm-group-2', 1, {
                        autoAlpha: 0,
                    }, 14)

                    .to('#gm-group-5', 1, {
                        autoAlpha: 1,
                    }, 13)
                    .to('#gm-group-5', 1, {
                        autoAlpha: 0,
                    }, 14)

                    .to('#sd-logo-inner-text-7', .5, {
                        autoAlpha: 1,
                    }, 14)
                    .to('#sd-logo-inner-text-7', .5, {
                        color: '#fde836',
                    }, 14)
                    .to('#sd-logo-inner-text-7', .5, {
                        color: '#e6afbe',
                    }, 15)


                    .to('#gm-group-3', 1, {
                        autoAlpha: 1,
                    }, 13.5)

                    .to('#gm-group-4', 1, {
                        autoAlpha: 1,
                    }, 13.5)

                    .to('#sd-logo-inner-text-8', .5, {
                        autoAlpha: 1,
                    }, 15)
                    .to('#sd-logo-inner-text-8', .5, {
                        color: '#fde836',
                    }, 15)

                    .to('#sd-logo-inner-text-social', .5, {
                        autoAlpha: 1,
                    }, 15)

                    .to('#gm-text', 1, {
                        autoAlpha: 1,
                    }, 15)
                    .to('[data-bg-promises]', 2, {
                        autoAlpha: 1,
                    }, 18);
            }

            init();
        }
    }
}
</script>
