<template>
    <div>
        <attendee-login
            v-if="!attendee"
            v-on:login-success="hideModal"
            class="mt-4"
        ></attendee-login>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
    computed: {
        ...mapState(['attendee']),
    },
    methods: {
        ...mapMutations([`hideModal`]),
    }
}
</script>
