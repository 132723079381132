<template>
    <div>
        <div class="font-source-sans-pro">
            <div class="text-blue text-2xl">{{ data.date || "" }}</div>
            <div class="text-blue text-xl mt-1">{{ data.time || "" }}</div>
            <div class="text-magenta text-2xl mt-1">{{ data.place || "" }}</div>
            <div class="text-blue text-xl mt-1">{{ data.address || "" }}</div>

            <div
                v-if="(delta && !spotSaved) || (delta > 1 && spotSaved)"
                class="mt-8 text-magenta text-lg leading-tight font-semibold"
            >
                This event location is not active yet.<br/>
                It requires {{ delta }} more attendee{{ delta > 1 ? "s" : "" }}
                to be activated.
            </div>
        </div>

        <attendee-login v-if="!attendee" class="pt-8 pb-4"></attendee-login>

        <div v-else class="mt-8 pb-4">
            <div v-if="!subscribeError">
                <div v-if="loading" class="w-full flex justify-center pt-8 pb-4">
                    <img src="/assets/front/img/loader2.svg" width="84px" height="84px"/>
                </div>

                <div v-else>
                    <div
                        v-if="redirecting"
                        class="font-point-demo-bold text-blue text-base text-center"
                    >
                        We are redirecting you to the event page...
                    </div>

                    <div v-else>
                        <div v-if="spotSaved">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 280.95 276.22"
                                width="40"
                                class="mx-auto mb-10"
                            >
                                <g>
                                    <path
                                        fill="#fd3670"
                                        class="fill"
                                        d="M142.86,276.22h-4.77C61.95,276.22,0,214.27,0,138.13a33.17,33.17,0,0,1,66.34,0,71.83,71.83,0,0,0,71.75,71.75h4.77a71.83,71.83,0,0,0,71.75-71.75,33.17,33.17,0,0,1,66.34,0C280.95,214.27,219,276.22,142.86,276.22Z"
                                    />
                                    <circle
                                        fill="#736ce4"
                                        class="fill"
                                        cx="33.17"
                                        cy="33.17"
                                        r="33.17"
                                    />
                                    <circle
                                        fill="#736ce4"
                                        class="fill"
                                        cx="247.78"
                                        cy="33.17"
                                        r="33.17"
                                    />
                                </g>
                            </svg>

                            <h2
                                class="font-point-demo-bold text-blue text-lg text-center uppercase"
                            >
                                We've sent you a text, we're excited to have you at Open Floor
                                <!-- <span class="magenta">DISCOLOGY</span> with us. -->
                            </h2>

                            <h3
                                v-if="trialMessage"
                                class="font-point-demo-bold text-magenta text-lg text-center uppercase underline mt-4"
                            >
                                Your subscription trial has started. You can save spots for
                                events with starting date within
                                {{ trialDays }} days.
                            </h3>

                            <div v-if="eventUrl" class="mt-4 flex justify-center">
                                <a
                                    class="w-full xsm:w-auto px-24 pt-4 pb-3 bg-lightBlue hover:bg-blue3 rounded-full leading-none font-point-demo-bold text-white text-xl sm:text-2xl"
                                    :href="eventUrl"
                                >Go To Event</a
                                >
                            </div>

                            <div
                                v-if="signupUrl"
                                class="mt-8 font-point-demo-bold text-base text-center text-blue"
                            >
                                Share this event with friends using this link:
                                <a class="text-magenta" :href="signupUrl">{{ signupUrl }}</a>
                            </div>

                            <div
                                class="mt-8 font-point-demo-bold text-magenta text-base text-center"
                            >
                                If you haven't received our message you can try again.
                            </div>
                        </div>

                        <div v-if="errors.length > 0" class="py-4">
                            <div
                                v-for="error in errors"
                                class="text-magenta text-lg text-center mt-1"
                            >
                                {{ error }}
                            </div>
                        </div>

                        <div class="flex justify-center mt-8">
                            <button
                                @click="saveSpot()"
                                type="submit"
                                class="w-full xsm:w-auto px-24 pt-4 pb-3 bg-lightBlue hover:bg-blue3 rounded-full leading-none font-point-demo-bold text-white text-xl sm:text-2xl"
                            >
                                {{ spotSaved ? "Resend" : "Save Spot" }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="text-center">
                <p class="text-magenta text-2xl mb-8">
                    Please subscribe below to save a spot
                </p>
                <a
                    :href="`/subscription`"
                    class="inline-block w-full xsm:w-auto px-24 pt-4 pb-3 bg-lightBlue hover:bg-blue3 rounded-full leading-none font-point-demo-bold text-white text-xl sm:text-2xl"
                >
                    Subscribe
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
    props: {
        data: Object,
        attendeeSubscribed: Boolean,
        trialDays: Number,
        trialExpired: Boolean,
        trialEndsAt: String
    },

    data() {
        return {
            loading: false,
            redirecting: false,
            spotSaved: false,
            trialMessage: null,
            subscribeError: null,
            errors: [],
            signupUrl: null,
            eventUrl: null,
            route: "/events/signup"
        };
    },

    watch: {
        spotSaved() {
            this.route = "/resend-signup";
        }
    },

    computed: {
        ...mapState(["attendee"]),

        delta() {
            if (
                this.data.min_attendees &&
                this.data.attendees_count < this.data.min_attendees
            ) {
                return this.data.min_attendees - this.data.attendees_count;
            }
            return false;
        }
    },

    methods: {
        saveSpot() {
            this.loading = true;
            this.errors = [];

            axios
                .post(this.route, {
                    event_id: this.data.event_id,
                    location_id: this.data.location_id
                })
                .then(response => {
                    if (response.data.redirect) {
                        this.loading = false;
                        this.redirecting = true;
                        setTimeout(
                            () => (window.location.href = response.data.redirect),
                            2000
                        );
                        return;
                    }

                    setTimeout(() => {
                        this.loading = false;
                        this.spotSaved = true;
                        this.trialMessage = !!(
                            response.data.events_count &&
                            response.data.events_count === 1 &&
                            !this.trialExpired
                        );
                        this.eventUrl = response.data.event_url || null;
                        this.signupUrl = response.data.signup_url || null;
                    }, 1000);
                })
                .catch(({response}) => {
                    this.loading = false;
                    const errors = response.data.errors;
                    const errorKeys = Object.keys(errors);

                    errorKeys.forEach(field => {
                        if (field === 'subscription') {
                            this.subscribeError = true
                        }
                        errors[field].forEach(error => this.errors.push(error));
                    });
                });
        }
    }
};
</script>
