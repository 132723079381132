<template>
    <div></div>
</template>

<script>
import Highcharts from 'highcharts'

export default {
    props: {
        title: {
            default: null
        },
        min: {
            type: Number,
            default: 1
        },
        max: {
            type: Number,
            default: 5
        },
        series: {
            type: Array,
            required: true,
        },
    },
    mounted() {
        const seriesDefault = {
            data: [],
            type: 'scatter',
            softThreshold: 0,
            marker: {
                symbol: 'circle'
            },
        }
        let options = {
            title: {
                text: this.title
            },
            xAxis: {
                labels: {
                    enabled: false
                }
            },
            yAxis: {
                max: this.max,
                min: this.min,
                maxPadding: 0,
                minPadding: 0,
                title: {
                    enabled: false
                }
            },
            colors: ['#5111F0', '#ca2a5a', '#efd947', '#5FD480'],
            tooltip: {
                headerFormat: '',
                pointFormat: '{point.y}'
            },
            credits: {
                enabled: false
            },
            series: []
        }

        this.series.forEach(value => {
            options.series.push({
                ...seriesDefault,
                ...value,
            })
        })

        Highcharts.chart(this.$el, options);
    }
}
</script>
