<template>
    <div>
        <div v-if="errors.length > 0" class="py-4">
            <div v-for="error in errors" class="text-magenta text-lg text-center mt-1">{{ error }}</div>
        </div>

        <div v-if="loading" class="w-full flex justify-center pt-8 pb-4">
            <img src="/assets/front/img/loader2.svg" width="84px" height="84px"/>
        </div>

        <div v-else class="pt-8 pb-4">
            <div v-if="!accountExists" class="text-center">
                <div v-if="accountExists === false">
                    <input
                        name="name"
                        v-model="name"
                        type="text"
                        placeholder="Your name"
                        required
                        class="appearance-none border-2 border-white focus:border-lightBlue rounded-large w-full py-3 px-5 mb-8 font-source-sans-pro text-lg bg-purpleLight text-purple focus:bg-white focus:text-blue2 focus:outline-none">
                    <input
                        name="email"
                        v-model="email"
                        type="email"
                        placeholder="Email address"
                        required
                        class="appearance-none border-2 border-white focus:border-lightBlue rounded-large w-full py-3 px-5 mb-8 font-source-sans-pro text-lg bg-purpleLight text-purple focus:bg-white focus:text-blue2 focus:outline-none">
                </div>

                <input
                    name="phone"
                    type="tel"
                    v-model="phone"
                    placeholder="Phone number"
                    required
                    class="appearance-none border-2 border-white focus:border-lightBlue rounded-large w-full py-3 px-5 font-source-sans-pro text-lg bg-purpleLight text-purple focus:bg-white focus:text-blue2 focus:outline-none">

                <div class="flex flex-wrap justify-center items-center mt-8">
                    <button
                        v-if="accountExists === false"
                        @click="resetForm"
                        type="button"
                        class="w-full order-2 sm:order-1 sm:w-auto px-8 pt-4 pb-3 sm:mr-3 border border-lightBlue hover:bg-lightBlue rounded-full leading-none font-point-demo-bold text-lightBlue hover:text-white text-xl sm:text-2xl uppercase">
                        Back
                    </button>
                    <button
                        v-if="accountExists === false"
                        @click="signUp"
                        type="submit"
                        class="w-full order-1 sm:order-2 sm:w-auto px-10 pt-4 pb-3 mb-6 sm:mb-0 event-login-button-background-color hover:bg-blue3 rounded-full leading-none font-point-demo-bold text-white text-xl sm:text-2xl uppercase">
                        Create account
                    </button>
                    <button
                        v-else
                        @click="login"
                        type="submit"
                        class="w-full xsm:w-auto px-24 pt-4 pb-3 event-login-button-background-color hover:bg-blue3 rounded-full leading-none font-point-demo-bold text-white text-xl sm:text-2xl uppercase">
                        Go
                    </button>
                </div>
            </div>

            <div v-if="accountExists" class="text-center text-blue2">
                <div class="mb-4">
                    We have sent a temporary password to your phone number.
                </div>

                <input
                    class="w-full py-3 px-5 mb-5 appearance-none border-2 border-white focus:border-lightBlue rounded-large font-source-sans-pro text-lg bg-purpleLight text-purple focus:bg-white focus:text-blue2 focus:outline-none"
                    type="number"
                    placeholder="Temporary password"
                    required
                    v-model="password"
                >

                <div class="mb-4 text-base" v-if="!retrying && retries < 2">
                    If you haven't received our message you can <span class="underline text-magenta cursor-pointer" @click="retry">try again</span>.
                </div>

                <button type="button"
                        class="inline-block w-full xsm:w-auto px-12 pt-4 pb-3 event-login-button-background-color hover:bg-blue3 rounded-full leading-none font-point-demo-bold text-white text-xl sm:text-2xl"
                        @click="validatePassword"
                >
                    Validate password
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import { mapMutations, mapState } from "vuex";

export default {
    data() {
        return {
            loading: false,
            accountExists: null,
            retrying: false,
            retries: 0,
            name: null,
            phone: null,
            email: null,
            password: null,
            errors: [],
        }
    },
    computed: {
        ...mapState(['attendee']),
    },
    methods: {
        ...mapMutations([`setAttendee`]),
        login() {
            this.errors = []
            this.loading = true

            return axios
                .post('/login', {
                    phone: this.phone,
                })
                .then(
                    response => {
                        this.errors = []
                        this.accountExists = true
                    },
                    error => this.handleErrorResponse(error)
                ).finally(() =>
                    this.loading = false
                )
        },
        signUp() {
            this.errors = []
            this.loading = true

            return axios
                .post('/signup-attendee', {
                    phone: this.phone,
                    name: this.name,
                    email: this.email,
                })
                .then(
                    response => {
                        this.errors = []
                        this.accountExists = true
                    },
                    error => this.handleErrorResponse(error)
                ).finally(() =>
                    this.loading = false
                )
        },
        retry() {
            this.retrying = true
            this.retries += 1

            this.login().then(() => this.retrying = false)
        },
        resetForm() {
            this.accountExists = null
            this.errors = []
        },
        validatePassword() {
            this.errors = []
            this.loading = true

            axios
                .post('/login/password', {
                    phone: this.phone,
                    password: this.password,
                })
                .then(
                    response => {
                        this.errors = []
                        this.setAttendee(true)
                        this.$emit('login-success', response.data)
                    },
                    error => this.handleErrorResponse(error)
                ).finally(() =>
                    this.loading = false
                )
        },
        handleErrorResponse(error) {
            if (error.response.status === 422) {
                if (error.response.data.errors.signup) {
                    this.accountExists = false
                }
                this.errors = Object.values(error.response.data.errors).flat()
            } else {
                this.errors = ['An unknown error occurred']
            }
        },
    }
}
</script>
